import React from 'react';
import { Global, css, CacheProvider } from '@emotion/core';
import createCache from '@emotion/cache';
import { homeBodyTheme } from 'constants/theme';
import WrapRootWithProviders from './wrapRootWithProviders';
import setGlobalStyles from './setGlobalStyles';
import utmTracking from 'utils/utmTracking';

const cache = createCache();
// enable compatibility mode
cache.compat = true;
export const wrapRootElement = (data) => <WrapRootWithProviders {...data} />;

export const onClientEntry = () => (
  <CacheProvider value={cache}>
    <Global
      styles={css`
        ${setGlobalStyles(homeBodyTheme)}
      `}
    />
  </CacheProvider>
);

export const onRouteUpdate = ({ location: { href } }) => {
  utmTracking(href);
};
