import * as amplitude from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';
import { QUOTE_VERSION_V2, QUOTE_VERSION_V3 } from '../constants/common';

let experiment;
export const CONTROL_VARIANT = 'control';

export const CHANNEL_NAME = 'Homebody Website';
const USER_ID = 'user_id';
const NAME_FIRST = 'first_name';
const NAME_LAST = 'last_name';
const EMAIL = 'email';
const ADDRESS = 'street_address';
const CITY = 'city';
const STATE = 'state';
const ZIP = 'zip_code';
const PHONE = 'phone';
const PROPERTY_ID = 'property_id';
const PROPERTY_NAME = 'property_name';
const LEAD_SOURCE = 'source';
const QUOTE_VERSION = 'quote_version';
const PACKAGE_NAME = 'package_name';
const BUTTON_LOCATION = 'button_location';
const CHANNEL = 'channel';
const PERSONAL_CONTENTS_AMOUNT = 'personal_contents_amount';
const LIABILITY_LIMIIT = 'liability_amount';
const DEDUCTIBLE = 'deductible';
const BILLING_FREQUENCY = 'billing_frequency';
const COVERAGE_BENEFITS = 'coverage_benefits';
const CONTRACTED_PROPERTY_SELECTED = 'contracted_property_selected';
const COVERAGE_START_DATE = 'coverage_start_date';
const PAYMENT_METHOD = 'payment_method';
const CARD_TYPE = 'card_type';
const ENROLLMENT_TYPE = 'enrollment_type';
const ENROLLMENT_CHANNEL = 'enrollment_channel';
const ID_THEFT = 'id_theft';
const ID_THEFT_ENDORSEMENTS_TOGGLE = 'id_theft_endorsements_toggle';
const ID_THEFT_ENDORSEMENTS_CHECKBOX_VALUE = 'id_theft_endorsements_checkbox_value';
const PRODUCT_CARD = 'product_card';
const ID_THEFT_PROVIDER = 'id_theft_provider';
const ID_THEFT_PLAN = 'id_theft_plan';
const PAGE_NAME = 'page_name';
const MANAGE_ID_THEFT = 'manage_id_theft';
const POLICY_AND_PAYMENTS_ID_THEFT = 'policy_and_payments_id_theft';
const IDENTITY_PROTECT_PAGE_VIEW_TYPE = 'identity_protect_page_view_type';

export const properties = {
  USER_ID,
  NAME_FIRST,
  NAME_LAST,
  EMAIL,
  ADDRESS,
  CITY,
  STATE,
  ZIP,
  PHONE,
  PROPERTY_ID,
  PROPERTY_NAME,
  LEAD_SOURCE,
  CHANNEL,
  PERSONAL_CONTENTS_AMOUNT,
  LIABILITY_LIMIIT,
  DEDUCTIBLE,
  BILLING_FREQUENCY,
  COVERAGE_BENEFITS,
  CONTRACTED_PROPERTY_SELECTED,
  COVERAGE_START_DATE,
  PAYMENT_METHOD,
  CARD_TYPE,
  ENROLLMENT_TYPE,
  ENROLLMENT_CHANNEL,
  ID_THEFT,
  QUOTE_VERSION,
  PACKAGE_NAME,
  BUTTON_LOCATION,
  ID_THEFT_ENDORSEMENTS_TOGGLE,
  ID_THEFT_ENDORSEMENTS_CHECKBOX_VALUE,
  PRODUCT_CARD,
  ID_THEFT_PROVIDER,
  ID_THEFT_PLAN,
  PAGE_NAME,
  MANAGE_ID_THEFT,
  IDENTITY_PROTECT_PAGE_VIEW_TYPE,
  POLICY_AND_PAYMENTS_ID_THEFT,
};

const EMAIL_ENTERED = 'Email Entered';
const CUSTOMER_INFO_ENTERED = 'Customer Info Entered';
const ENROLLMENT_CONFIRMED = 'Enrollment Confirmed';
const QUOTE_STARTED = 'Quote Started';
const ENROLL_BUTTON_CLICKED = 'Enroll Button Clicked';
const QUOTE_VIEWED = 'Quote Viewed';
const PROPERTY_SELECTED = 'Property Selected';
const COVERAGE_START_DATE_SELECTED = 'Coverage Start Date Selected';
const ADD_ADDITIONAL_INSURED_CLICKED = 'Add Additional Insured Clicked';
const ADDITIONAL_INSURED_SAVED = 'Additional Insured Saved';
const PAYMENT_ENTERED = 'Payment Entered';
const EDIT_QUOTE_CLICKED = 'Edit Quote Clicked';
const ENROLLMENT_SUBMITTED = 'Enrollment Submitted';
const TIMEOUT_TRIGGERED = 'Timeout Triggered';
const EXISTING_EMAIL_ENTERED = 'Existing Email Entered';
const PAGE_NOT_FOUND = 'Page Not Found';
const ADD_INTERESTED_PARTY_CLICKED = 'Add Interested Party Clicked';
const INTERESTED_PARTY_SAVED = 'Interested Party Saved';
const CUSTOMIZE_BUTTON_CLICKED = 'Customize Button Clicked';
const ID_THEFT_ENDORSEMENTS_TOGGLE_CLICK = 'Id Theft Endorsements Toggle Click';
const ID_THEFT_CHECKBOX = 'Id Theft Checkbox';
const POLICY_DETAILS_CLICK = 'Policy Details Click';
const PAGE_VIEW = 'Page View';
const IDENTITY_PROTECT_PAGE_VIEW = 'Identity Protect Page View';

export const events = {
  EMAIL_ENTERED,
  CUSTOMER_INFO_ENTERED,
  ENROLLMENT_CONFIRMED,
  QUOTE_STARTED,
  ENROLL_BUTTON_CLICKED,
  QUOTE_VIEWED,
  PROPERTY_SELECTED,
  COVERAGE_START_DATE_SELECTED,
  ADD_ADDITIONAL_INSURED_CLICKED,
  ADDITIONAL_INSURED_SAVED,
  PAYMENT_ENTERED,
  EDIT_QUOTE_CLICKED,
  ENROLLMENT_SUBMITTED,
  TIMEOUT_TRIGGERED,
  EXISTING_EMAIL_ENTERED,
  PAGE_NOT_FOUND,
  ADD_INTERESTED_PARTY_CLICKED,
  INTERESTED_PARTY_SAVED,
  CUSTOMIZE_BUTTON_CLICKED,
  ID_THEFT_ENDORSEMENTS_TOGGLE_CLICK,
  ID_THEFT_CHECKBOX,
  POLICY_DETAILS_CLICK,
  PAGE_VIEW,
  IDENTITY_PROTECT_PAGE_VIEW,
};

const getApiKey = () => {
  let apiKey = 'e355e0fdbb7532b674038dcc8e2a9030';
  if (process.env.NODE_BRANCH === 'live') {
    apiKey = '12c6131ddcdf55c11ac4a5f5243ee519';
  }
  return apiKey;
};

const getDeploymentKey = () => {
  let deploymentKey = 'client-FkWuNLQsk3xbhoZ77YE37uIhee9nPELH';
  if (process.env.NODE_BRANCH === 'live') {
    deploymentKey = 'client-asX6LB4yOZMTDeDW3F4e7qg0Si07A1a6';
  }
  return deploymentKey;
};

/**
 * This will initialize amplitude and needs to be called on app initialization.
 */
export const initialize = async () => {
  if (typeof window !== 'undefined') {
    amplitude.init(getApiKey(), {
      defaultTracking: false,
    });

    experiment = Experiment.initializeWithAmplitudeAnalytics(getDeploymentKey());
    await experiment.start();
    await experiment.fetch();
  }
};

export const getQuoteVersionVariant = async () => {
  amplitude.init(getApiKey(), {
    defaultTracking: false,
  });
  const experimentObj = Experiment.initializeWithAmplitudeAnalytics(getDeploymentKey());
  await experimentObj.start();
  await experimentObj.fetch();
  const variant = await experimentObj.variant('hb-quotes-version');

  const quoteVersion = [QUOTE_VERSION_V2, QUOTE_VERSION_V3].includes(variant.value) ? variant.value : QUOTE_VERSION_V2;

  if (typeof window !== 'undefined') {
    window.quoteVersion = quoteVersion;
  }

  return quoteVersion;
};

const handleUserProperties = (props) => {
  const identifyEvent = new amplitude.Identify();

  let firstName;
  let lastName;
  props.forEach((property) => {
    identifyEvent.set(property.name, property.value);
    if (property.name === NAME_FIRST) {
      firstName = property.value;
    }
    if (property.name === NAME_LAST) {
      lastName = property.value;
    }
    if (property.name === USER_ID) {
      amplitude.setUserId(property.value);
    }
  });

  if (firstName !== undefined || lastName !== undefined) {
    identifyEvent.set('full_name', `${firstName ?? ''} ${lastName ?? ''}`);
  }

  amplitude.identify(identifyEvent);
};

const handleEvent = (props) => {
  const eventName = props.name;
  const syncProperties = {};
  if (props.properties !== undefined) {
    props.properties.forEach((property) => {
      const propertyName = property.name;
      syncProperties[propertyName] = property.value;
    });
  }
  amplitude.track(eventName, syncProperties);
};

export const sync = (props) => {
  if (props.userProperties !== undefined) {
    handleUserProperties(props.userProperties);
  }
  // Need to handle events after userProperties so the userProperties will be associated with the event.
  if (props.event !== undefined) {
    handleEvent(props.event);
  }
};

export const useVariant = (flagKey) => {
  if (!experiment) {
    return CONTROL_VARIANT;
  }
  const variant = experiment.variant(flagKey, { value: 'on' });
  let result = CONTROL_VARIANT;
  if (variant.value !== 'on') {
    result = variant;
  }
  return result;
};
